<template>
  <div>
    <we-card title="監視">
      <slot>
        <div v-if="loading" class="loading">
          <md-progress-spinner
            class="md-accent" 
            :md-stroke="3" 
            md-mode="indeterminate"
          />
        </div>
        <div v-else class="md-layout">
          <div class="md-layout-item md-size-100">
            <we-button
              v-for="(name, key) in sections"
              :key="key"
              size="small"
              :outline="section===name ? null : 'red'"
              @click="changeSection(name)"
            >
              {{name}}
            </we-button>
          </div>
          <template v-if="livings.length > 0">
            <div
              class="md-layout-item md-size-30 md-xsmall-size-100 md-small-size-50"
              v-for="(living, key) in livings"
              :key="key"
            >
              <we-monitor-card
                :livingId="living.eventId"
                :title="living.title"
                :avatar="living.liver.avatar"
                :name="living.liver.nickName"
                :imageCover="living.imageCover"
                :period="getPeriod(living)"
                :section="section"
                :page="page"
              />
            </div>
            <div class="md-layout-item md-size-100">
              <div class="table-pagination" v-if="loading === false">
                <span class="prev-page" @click="prevPage">&lt;前頁</span>
                <span class="next-page" @click="nextPage">次頁&gt;</span>
              </div>
            </div>
          </template>
          <div v-else class="md-layout-item md-size-100" style="text-align: center; padding: 80px 0">
            データありません。
          </div>
        </div>
      </slot>
    </we-card>
  </div>
</template>
<script>
import { ajax, common, config } from '../../utils';
export default {
  data() {
    return {
      livings: [],
      loading: true,
      sections: [],
    };
  },
  created() {
    this.getLivingSections();
  },
  computed: {
    section() {
      if (this.$route.query.section) {
        return this.$route.query.section;
      } else if (this.sections.length > 0) {
        return this.sections[0];
      } else {
        return null;
      }
    },
    page: {
      get() {
        if (this.$route.query.page) {
          return parseInt(this.$route.query.page);
        } else {
          return 1;
        }
      },
      set(val) {
        this.$router.push({ name: this.$route.name, query: {section: this.section, page: val} });
      },
    },
  },
  methods: {
    changeSection(s) {
      this.$router.push({ name: this.$route.name, query: {section: s, page: 1} });
      this.getLivingList(s);
    },
    getLivingSections() {
      const vm = this;
      ajax.get(config.api.living.sections).then(data => {
        vm.sections = data.map(i => i === null ? '' : i);
        let s = vm.section;
        if (!s) {
          s = vm.sections.length > 0 ? vm.sections[0] : null;
        }
        // vm.$router.push({ name: this.$route.name, query: {section: s, page: 1} });
        vm.getLivingList(vm.section);
      })
    },
    getLivingList(section) {
      if (section === null) {
        this.loading = false
        return;
      }
      const vm = this;
      vm.loading = true;
      ajax.get(`${config.api.living.list}?page=${this.page}&size=6&section=${section}`).then(data => {
        if (vm.page > 1 && data.length === 0) {
          vm.page -= 1;
        } else {
          vm.livings = data;
        }
      }).finally(() => vm.loading = false);
    },
    getPeriod(living) {
      const start = new Date(living.startTime);
      const end = new Date(living.endTime);
      return `${common.formatDate(start, 'YYYY/MM/DD hh:mm')}-${common.formatDate(end, 'hh:mm')}`;
    },
    prevPage() {
      if (this.page > 1) {
        this.page -= 1;
        this.getLivingList(this.section);
      }
    },
    nextPage() {
      if (this.livings.length === 6) {
        this.page += 1;
        this.getLivingList(this.section);
      }
    },
  }
}
</script>